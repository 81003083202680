<template>
  <svg
    width="110"
    height="20"
    viewBox="0 0 110 20"
    fill="none"
    class="w-28 h-5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 10.96 2.045 L 12.96 7.961 L 13.067 8.277 L 13.412 8.277 L 19.885 8.277 L 14.648 11.933 L 14.369 12.128 L 14.475 12.443 L 16.476 18.36 L 11.239 14.703 L 10.96 14.509 L 10.68 14.703 L 5.444 18.36 L 7.444 12.443 L 7.551 12.128 L 7.272 11.933 L 2.035 8.277 L 8.508 8.277 L 8.853 8.277 L 8.96 7.961 L 10.96 2.045 Z"
      fill="#FFC805"
      stroke="#1E1E1E"
      style=""
    />
    <path
      d="M 32.764 2.01 L 34.764 7.926 L 34.871 8.242 L 35.216 8.242 L 41.689 8.242 L 36.452 11.898 L 36.173 12.093 L 36.279 12.408 L 38.28 18.325 L 33.043 14.668 L 32.764 14.474 L 32.484 14.668 L 27.248 18.325 L 29.248 12.408 L 29.355 12.093 L 29.076 11.898 L 23.839 8.242 L 30.312 8.242 L 30.657 8.242 L 30.764 7.926 L 32.764 2.01 Z"
      fill="#FFC805"
      stroke="#1E1E1E"
      style=""
    />
    <path
      d="M 54.852 1.987 L 56.852 7.903 L 56.959 8.219 L 57.304 8.219 L 63.777 8.219 L 58.54 11.875 L 58.261 12.07 L 58.367 12.385 L 60.368 18.302 L 55.131 14.645 L 54.852 14.451 L 54.572 14.645 L 49.336 18.302 L 51.336 12.385 L 51.443 12.07 L 51.164 11.875 L 45.927 8.219 L 52.4 8.219 L 52.745 8.219 L 52.852 7.903 L 54.852 1.987 Z"
      fill="#FFC805"
      stroke="#1E1E1E"
      style=""
    />
    <path
      d="M 77.058 2.022 L 79.058 7.938 L 79.165 8.254 L 79.51 8.254 L 85.983 8.254 L 80.746 11.91 L 80.467 12.105 L 80.573 12.42 L 82.574 18.337 L 77.337 14.68 L 77.058 14.486 L 76.778 14.68 L 71.542 18.337 L 73.542 12.42 L 73.649 12.105 L 73.37 11.91 L 68.133 8.254 L 74.606 8.254 L 74.951 8.254 L 75.058 7.938 L 77.058 2.022 Z"
      fill="#FFC805"
      stroke="#1E1E1E"
      style=""
    />
    <path
      d="M 98.886 1.985 L 100.886 7.901 L 100.993 8.217 L 101.338 8.217 L 107.811 8.217 L 102.574 11.873 L 102.295 12.068 L 102.401 12.383 L 104.402 18.3 L 99.165 14.643 L 98.886 14.449 L 98.606 14.643 L 93.37 18.3 L 95.37 12.383 L 95.477 12.068 L 95.198 11.873 L 89.961 8.217 L 96.434 8.217 L 96.779 8.217 L 96.886 7.901 L 98.886 1.985 Z"
      fill="#FFC805"
      stroke="#1E1E1E"
      style=""
    />
  </svg>
</template>
