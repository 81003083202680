<template>
  <svg
    id="clock_icon_svg"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
    xml:space="preserve"
    class="h-12 w-12"
    style="enable-background: new 0 0 500 500"
  >
    <g>
      <path
        d="M207.33,91.86c-80.59,0-146.16,65.57-146.16,146.16c0,80.6,65.57,146.17,146.16,146.17c2.48,0,4.48-2,4.48-4.48
		c0-2.48-2-4.48-4.48-4.48c-75.66,0-137.2-61.56-137.2-137.21s61.55-137.2,137.2-137.2s137.2,61.55,137.2,137.2
		c0,2.48,2,4.48,4.48,4.48c2.48,0,4.48-2,4.48-4.48C353.49,157.43,287.92,91.86,207.33,91.86z"
      />
      <path
        d="M233.48,410.7c-8.62,1.29-17.42,1.93-26.15,1.93c-96.28,0-174.6-78.33-174.6-174.61c0-96.27,78.32-174.6,174.6-174.6
		s174.61,78.32,174.61,174.6c0,0,0.06,4.11,4.22,4.11c4.16,0,4.73-4.11,4.73-4.11c0-101.22-82.35-183.55-183.56-183.55
		S23.77,136.8,23.77,238.02c0,101.22,82.35,183.57,183.56,183.57c9.16,0,18.4-0.68,27.46-2.04c2.45-0.36,4.14-2.64,3.77-5.08
		C238.2,412.01,235.88,410.35,233.48,410.7z"
      />
      <path
        d="M207.33,256.07c9.96,0,18.05-8.1,18.05-18.05c0-1.72-0.26-3.37-0.71-4.95l55.13-51.58c1.8-1.69,1.9-4.52,0.21-6.33
		c-1.69-1.81-4.52-1.9-6.33-0.21l-53.69,50.24c-3.26-3.21-7.73-5.2-12.66-5.2c-3.12,0-6.05,0.79-8.61,2.19l-59.85-74.07
		c-1.56-1.92-4.37-2.23-6.3-0.66c-1.92,1.55-2.22,4.37-0.66,6.3l60.25,74.56c-1.81,2.81-2.87,6.14-2.87,9.72
		C189.29,247.97,197.38,256.07,207.33,256.07z M216.42,238.02c0,5.01-4.08,9.1-9.09,9.1c-5.01,0-9.09-4.08-9.09-9.1
		c0-5,4.08-9.08,9.09-9.08C212.34,228.94,216.42,233.01,216.42,238.02z"
      />
      <path
        d="M211.81,135.44v-18.06c0-2.47-2-4.48-4.48-4.48c-2.47,0-4.48,2-4.48,4.48v18.06c0,2.47,2,4.48,4.48,4.48
		C209.81,139.92,211.81,137.91,211.81,135.44z"
      />
      <path
        d="M84.82,233.54c-2.48,0-4.48,2-4.48,4.48c0,2.48,2,4.48,4.48,4.48h18.06c2.48,0,4.48-2,4.48-4.48c0-2.48-2-4.48-4.48-4.48
		H84.82z"
      />
      <path
        d="M300.41,255.75c-25.24,0-49.41,16.9-49.41,47.06c0,23.1,16.69,43,42.14,43c11.55,0,25.03-4.06,39.57-13.69
		c-6.63,26.1-26.31,50.27-64.17,65.67l6.85,9.84c49.63-19.25,73.8-60.97,73.8-95.83C349.18,281.2,332.49,255.75,300.41,255.75z
		 M334.85,319.28c-18.18,12.83-30.59,15.19-39.36,15.19c-17.11,0-30.38-13.69-30.38-31.66c0-22.46,17.33-35.72,35.3-35.72
		c24.39,0,34.87,23.74,34.87,44.71C335.27,314.36,335.06,316.93,334.85,319.28z"
      />
      <path
        d="M421.05,255.75c-31.66,0-54.76,30.59-54.76,75.94s23.1,75.94,54.76,75.94s54.76-30.59,54.76-75.94
		S452.71,255.75,421.05,255.75z M421.05,396.29c-23.53,0-40.64-26.1-40.64-64.6s17.11-64.6,40.64-64.6
		c23.53,0,40.64,26.1,40.64,64.6S444.58,396.29,421.05,396.29z"
      />
    </g>
  </svg>
</template>
